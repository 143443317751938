import { type ContractFunding } from '@paid-ui//types/fundings';
import { PaymentTermsType } from '@paid-ui/constants';
import { defaultAddress } from '@paid-ui/constants/address';
import { ClaimMethod } from '@paid-ui/enums/claim';
import {
  type ContractRole,
  ContractSource,
  type ContractState,
  type ContractTemplate,
  type ContractType,
} from '@paid-ui/enums/contract';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import { type SupercontractCode } from '@paid-ui/enums/supercontract';
import { type Attachment, type BankGuaranteeAttachment } from '@paid-ui/schemas/zod/attachment';
import { type TaxableAmount } from '@paid-ui/types';
import { type Address } from '@paid-ui/types/address';
import {
  type ContractBreakdown,
  type ContractInsuranceItem,
  type ContractLoanInfo,
  type ContractPrice,
  type ContractRequirement,
  type ContractResponsibility,
  type DelayAllowance,
  type LiquidatedDamages,
  type PracticalCompletion,
} from '@paid-ui/types/contracts';
import { type ContractParticipantParty } from '@paid-ui/types/parties';
import { type PaymentSchedule } from '@paid-ui/types/payment-schedule';
import { type RequiringAction } from '@paid-ui/types/requiring-actions';
import { type ContractRetention, type RetentionSchedule } from '@paid-ui/types/retentions';
import { type Timeline } from '@paid-ui/types/timeline';
import { type Adjustment, type Variation } from '@paid-ui/types/variations';
import { type WorkItem } from '@paid-ui/types/work-items';
import type { Dayjs } from 'dayjs';

import { type Payment } from '../payment';
import { type Contract, type DefaultContractAction } from './types';

export const initialState = {
  id: '',
  contractName: '',
  fullContractName: '',
  suburbAndState: '',
  currentContract: null as Contract,
  contractPrice: 0,
  contractState: '' as ContractState | '',
  contractStateText: 'Loading...',
  contractTemplate: '' as ContractTemplate | '',
  templateCode: '' as SupercontractCode | '',
  contractType: '' as ContractType,
  contractAddress: defaultAddress as Address,
  formattedAddress: '',
  center: { lat: -24.15, lng: 133.25 },
  zoom: 4,
  timezone: '',
  descriptionOfWorks: '',
  source: ContractSource.ON_PLATFORM as ContractSource,
  hasAllSigned: false,

  partyName: '',
  currentParty: null as ContractParticipantParty | null,
  theOtherPartyName: '',
  theOtherParty: null as ContractParticipantParty | null,

  numberOfParticipants: 0,
  numberOfSignatories: 0,
  numberOfJoined: 0,
  numberOfApproved: 0,
  numberOfSigned: 0,

  isPayer: false,
  isPayee: false,
  isPayerHasAccount: false,
  isInviter: false,
  isInvitee: false,
  isSuperintendent: false,
  isArchitect: false,

  payeeParty: null as ContractParticipantParty | null,
  payerParty: null as ContractParticipantParty | null,
  participantParties: [] as ContractParticipantParty[],
  inviteeParty: null as ContractParticipantParty | null,
  inviterParty: null as ContractParticipantParty | null,
  lastReviewParty: null as ContractParticipantParty | null,

  superintendentPartyName: '',
  superintendentParty: null as ContractParticipantParty | null,

  architectName: '',
  architectParty: null as ContractParticipantParty | null,

  roles: {} as Partial<Record<ContractRole, ContractResponsibility>>,
  responsibilities: {
    [ContractAction.CONTRACT_REVIEW]: [],
    [ContractAction.CONTRACT_AMEND]: [],
    [ContractAction.CONTRACT_SIGN]: [],
    [ContractAction.CLAIM_SUBMIT]: [],
    [ContractAction.CLAIM_REVIEW]: [],
    [ContractAction.CLAIM_ASSESSED]: [],
    [ContractAction.CLAIM_PAY]: [],
    [ContractAction.VARIATION_SUBMIT]: [],
    [ContractAction.VARIATION_REVIEW]: [],
  } as Record<ContractAction, ContractRole[]>,

  lineWorkItems: [] as WorkItem[],
  variationWorkItems: [] as WorkItem[],
  workItems: [] as WorkItem[],
  numberOfWorkItems: 0,
  numberOfLineWorkItems: 0,
  numberOfVariationWorkItems: 0,
  lineWorkItemsAmount: 0,
  variationWorkItemsAmount: 0,
  workItemsAmount: 0,
  lineWorkItemsAssessedAmount: 0,
  variationWorkItemsAssessedAmount: 0,
  totalAssessedAmount: 0,
  contractFundings: [] as ContractFunding[],
  timelines: [] as Timeline[],

  retentions: [] as ContractRetention[],
  retentionSchedule: null as RetentionSchedule | null,
  estimatedRetention: 0,
  retainableInfo: {
    assessed: {} as TaxableAmount,
    claimable: {} as TaxableAmount,
    retainable: {} as TaxableAmount,
    maxRetainable: {} as TaxableAmount,
    remainingRetainable: {} as TaxableAmount,
  },

  loan: null as ContractLoanInfo | null,
  landOwner: '',

  paymentStructure: ClaimMethod.STAGED,
  paymentSchedule: null as PaymentSchedule | null,
  paymentTerms: {
    days: 14,
    type: PaymentTermsType.DEFAULT,
  },

  price: {
    initial: 0,
    current: 0,
    paid: {
      amount: 0,
      percentage: 0,
    },
    remaining: {
      amount: 0,
      percentage: 0,
    },
  } as ContractPrice,

  breakdown: {
    initial: {
      value: 0,
      exclGst: 0,
      inclGst: 0,
    },
    current: {
      value: 0,
      exclGst: 0,
      inclGst: 0,
    },
    provisional: {
      value: 0,
      exclGst: 0,
      inclGst: 0,
    },
    paid: {
      amount: 0,
      percentage: 0,
    },
    remaining: {
      amount: 0,
      percentage: 0,
    },
    variations: {
      total: {
        value: 0,
        exclGst: 0,
        inclGst: 0,
      },
      provisional: {
        value: 0,
        exclGst: 0,
        inclGst: 0,
      },
      approved: {
        value: 0,
        exclGst: 0,
        inclGst: 0,
      },
      unapproved: {
        value: 0,
        exclGst: 0,
        inclGst: 0,
      },
    },
  } as ContractBreakdown,

  payments: [] as Payment[],
  provisionalClaims: [] as Payment[],
  totalNumberOfPayments: 0,
  numberOfClaimedPayments: 0,
  numberOfUnclaimedPayments: 0,
  numberOfProvisionalClaims: 0,

  hasWorkCommenced: false,
  hasWorkCompleted: false,
  commencementDate: null as Dayjs | null,
  contractualCompletionDate: null as Dayjs | null,
  actualCompletionDate: null as Dayjs | null,
  anticipatedCommencementDate: null as Dayjs | null,
  anticipatedCompletionDate: null as Dayjs | null,
  initialConstructionDays: 0,
  constructionDays: 0,
  remainingDays: 0,
  overdueDays: 0,

  gstFree: false,
  gstRate: 10,
  priceIncludeGst: true,

  interestRateForLatePayments: 0,
  builderMargin: 0,
  ownerCausedDelays: 0,
  lateCompletion: 0,
  defectsLiabilityPeriod: 0,
  additionalDaysFromVariations: 0,

  insurances: [] as ContractInsuranceItem[],
  requirements: [] as ContractRequirement[],
  isDepositInsuranceRequired: false,
  isDepositInsuranceExists: false,
  depositInsuranceCertificate: null as Attachment | null,
  isContractInsuranceExists: false,
  contractInsuranceCertificate: null as Attachment | null,

  masterContractUrl: '',
  originalContractUrl: '',
  uploadFolderId: '',
  pdfContract: null as Attachment | null,
  notices: [] as Attachment[],
  attachments: [] as Attachment[],
  certificates: [] as Attachment[],
  bankGuarantees: [] as BankGuaranteeAttachment[],
  reports: [] as Attachment[],
  specialConditions: '',
  liquidatedDamages: null as LiquidatedDamages | null,
  delayAllowances: [] as DelayAllowance[],
  practicalCompletions: [] as PracticalCompletion[],

  variations: [] as Variation[],
  adjustments: [] as Adjustment[],
  primeCostAdjustments: [] as Adjustment[],
  provisionalSumAdjustments: [] as Adjustment[],
  variationsAndAdjustments: [] as Array<Variation | Adjustment>,
  numberOfVariations: 0,
  numberOfAdjustments: 0,
  numberOfVariationsAndAdjustments: 0,

  requiringActions: [] as RequiringAction[],
  defaultActions: [] as DefaultContractAction[],
  dropdownActions: [] as DefaultContractAction[],

  statuses: {
    isContractAccepted: false,
    isContractClosed: false,
    isContractExecuted: false,
    isDraftContract: false,
    isOneLastPaymentLeft: false,
    isOffPlatformContract: false,
    isPrimeContract: false,
    isPeriodic: false,
    isRBNContract: false,
    isSuperContract: false,
  },

  permissions: {
    canAbandonContract: false,
    canCreateClaim: false,
    canCreateVariation: false,
    canResendInvitation: false,
    canReviewContract: false,
    canSendCommencementNotice: false,
    canSendCompletionNotice: false,
    canSignContract: false,
    canViewContract: false,
    canClaimRetention: false,
    canRevokeContract: false,
    canEditDraftContract: false,
    canDeleteDraftContract: false,
  },

  paymentRef: '',

  snapshot: null as Record<string, any> | null,

  additionalData: {} as Record<string, any>,

  forceUpdate: 0,
  loading: false,
};

export type ContractManagerState = typeof initialState;
